import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BasicLanguage from "../components/languages/Basic";
import AddRounded from "@mui/icons-material/AddRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SBXStyles from "../components/utils/Styles";
import languageState from "../recoil/atoms/languageState";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Typography from "../components/parts-ui/Typography";
import { db, storage } from "../components/utils/firebase";
import { collection, addDoc } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import facilityState from "../recoil/atoms/facilityState";
import { getNameByUid } from "../components/utils/getUser";
import { deleteObject, getMetadata, ref, uploadBytes } from "@firebase/storage";
import StorageImage from "../components/parts/StorageImage";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSurveyQuestions } from "../hooks/surveyQuestions";
import { SurveyQuestionEditForm } from "../components/parts/forms/SurveyQuestionEditForm";
import PropTypes from "prop-types";
import { useQrs } from "../hooks/qrs";
import {
  acceptInput,
  isImageFile,
  storageRefImage,
} from "../components/utils/Conditions";
import QrRequestColor from "../components/parts/QrRequestColor";
import QrDownload from "../components/parts/facility/qr/QrDownload";
import QrRequests from "../components/parts/facility/settings/QrRequests";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;

const QrLogo = (props) => {
  const params = useParams();
  const facilityId = params.facilityId;
  const [exist, setExist] = useState(false);
  const setFetch = props.setFetch;
  const clickDeleteButton = () => {
    const yesOrNo = window.confirm(BasicLanguage.qr.delete[props.language]);
    if (yesOrNo) {
      deleteFileOnStorage();
    }
  };

  const deleteFileOnStorage = () => {
    const storageRef = ref(storage, storageRefImage(facilityId));

    deleteObject(storageRef)
      .then(() => {
        setFetch(Math.random());
      })
      .catch((error) => {
        console.error(error);
        alert(BasicLanguage.alert.error.default[props.language]);
      });
  };
  useEffect(() => {
    const storageRef = ref(storage, storageRefImage(facilityId));
    getMetadata(storageRef)
      .then(() => {
        setExist(true);
      })
      .catch(() => {
        setExist(false);
      });
  }, [facilityId, props.fetch]);

  return (
    <>
      {exist ? (
        <Box
          sx={{
            width: "200px",
            height: "200px",
            border: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <StorageImage
            imagePath={storageRefImage(facilityId)}
            sx={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={clickDeleteButton}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      ) : (
        <EditQrLogo setFetch={setFetch} />
      )}
    </>
  );
};

QrLogo.propTypes = {
  fetch: PropTypes.number,
  setFetch: PropTypes.func,
  language: PropTypes.string,
};

const EditQrLogo = (props) => {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facilityId = params.facilityId;
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const refFile = useRef();

  const setFetch = props.setFetch;

  const cancel = () => {
    setImage(null);
    refFile.current.value = null;
  };

  const submit = () => {
    setImage(null);
    refFile.current.value = null;
    const storageRef = ref(storage, storageRefImage(facilityId));

    uploadBytes(storageRef, imageFile).then(() => {
      setFetch(Math.random());
    });
  };

  const imageSelected = (e) => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileSize = file.size;
      if (fileSize === 0) return false;

      const fileMib = fileSize / 1024 ** 2;
      if (fileMib <= 10 && isImageFile(file)) {
        setImage(reader.result);
        setImageFile(file);
      } else {
        alert(BasicLanguage.alert.file[language]);
      }
    };
    reader.readAsDataURL(file);
  };
  return (
    <Box>
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Stack spacing={1}>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              border: "1px solid #ddd",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {image ? (
              <img
                src={image}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                alt="QR Logo"
              />
            ) : (
              <Typography sx={{ textAlign: "center" }}>
                {BasicLanguage.qr.noFile[language]}
              </Typography>
            )}
          </Box>

          <label htmlFor="imageFileButton">
            <input
              id="imageFileButton"
              type="file"
              onChange={imageSelected}
              accept={acceptInput}
              style={{ display: "none" }}
              ref={refFile}
            />
            <Button component="span" variant="outlined" fullWidth>
              {BasicLanguage.request.uploadPictureBtn[language]}
            </Button>
          </label>
        </Stack>
        <Box>
          <Stack spacing={1} direction="row">
            {image && (
              <>
                <Button
                  component="span"
                  variant="outlined"
                  color="success"
                  onClick={submit}
                >
                  {BasicLanguage.common.ok[language]}
                </Button>
                <Button
                  component="span"
                  variant="outlined"
                  color="error"
                  onClick={cancel}
                >
                  {BasicLanguage.common.cancel[language]}
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

EditQrLogo.propTypes = {
  setFetch: PropTypes.func,
};

const Qr = () => {
  const language = useRecoilValue(languageState);
  const params = useParams();
  const facility = useRecoilValue(facilityState);
  const facilityId = params.facilityId;
  const { qrs, loading } = useQrs(facilityId);
  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentQrs = qrs ? qrs.slice(indexOfFirstItem, indexOfLastItem) : [];

  const [fetch, setFetch] = useState(null);
  const totalPages = Math.ceil((qrs?.length || 0) / ITEMS_PER_PAGE);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography className="pageTitle" variant="h4">
        {BasicLanguage.common.menu.qr[language]}
        <IconButton LinkComponent={Link} to="./add">
          <AddRounded />
        </IconButton>
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        {BasicLanguage.qr.description[language]}
      </Typography>
      <Box sx={SBXStyles.mainBox}>
        <QrDownload />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{BasicLanguage.qr.place[language]}</TableCell>
              <TableCell>{BasicLanguage.qr.address[language]}</TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell colSpan={5}>
                <Box
                  sx={{
                    display: "flex",
                    height: 200,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            ) : (
              <>
                {currentQrs.map((qr) => {
                  return (
                    <TableRow key={qr.id}>
                      <TableCell
                        sx={{
                          textDecorationLine: !qr.active
                            ? "line-through"
                            : "none",
                          color: !qr.active ? "#cccccc" : "#000000",
                        }}
                      >
                        {qr.place}
                      </TableCell>
                      <TableCell
                        sx={{
                          textDecorationLine: !qr.active
                            ? "line-through"
                            : "none",
                          color: !qr.active ? "#cccccc" : "#000000",
                        }}
                      >
                        {qr.assignee?.map((y) => (
                          <Typography key={y}>
                            {getNameByUid(facility, y)}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell>
                        <IconButton LinkComponent={Link} to={"./" + qr.id}>
                          <SettingsOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}

            {!loading && qrs.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      width: "100%",
                      height: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {BasicLanguage.qr.none[language]}
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  gap={2}
                  borderRadius={"100%"}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1 || qrs?.length === 0}
                  >
                    <KeyboardArrowLeftIcon />
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages || qrs?.length === 0}
                  >
                    <KeyboardArrowRightIcon />
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <List></List>
      </Box>
      <QrRequests />
      <Box sx={SBXStyles.mainBox}>
        <Typography variant="h5">
          {BasicLanguage.qr.settings.title[language]}
        </Typography>
        <Typography>{BasicLanguage.qr.limit[language]}</Typography>
        <QrLogo fetch={fetch} setFetch={setFetch} language={language} />
        <QrRequestColor />
      </Box>
      <Box sx={SBXStyles.mainBox}>
        <Typography variant="h5">
          {BasicLanguage.qr.survey.title[language]}
        </Typography>
        <SurveyTable facilityId={facilityId} language={language} />
      </Box>
    </Box>
  );
};

const SurveyTable = ({ facilityId, language }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "20%" }}></TableCell>
          <TableCell sx={{ width: "80%" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <SurveyQuestionsRow facilityId={facilityId} language={language} />
      </TableBody>
    </Table>
  );
};

SurveyTable.propTypes = {
  facilityId: PropTypes.string,
  language: PropTypes.string,
};
const SurveyQuestionsRow = ({ facilityId, language }) => {
  const surveyQuestions = useSurveyQuestions(facilityId);

  return (
    <TableRow>
      <TableCell>{BasicLanguage.qr.surveyQuestions[language]}</TableCell>
      <TableCell>
        <List>
          {surveyQuestions.map((surveyQuestion) => (
            <ListItem key={surveyQuestion.id}>
              <SurveyQuestionEditForm
                facilityId={facilityId}
                surveyQuestion={surveyQuestion}
                canUpdate={true}
              />
            </ListItem>
          ))}
          <QuestionAddItem facilityId={facilityId} />
        </List>
      </TableCell>
    </TableRow>
  );
};

SurveyQuestionsRow.propTypes = {
  facilityId: PropTypes.string,
  language: PropTypes.string,
};

const QuestionAddItem = ({ facilityId }) => {
  return (
    <ListItem>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          const question = event.target[0].value;
          await addQuestion(facilityId, question);
          event.target.reset();
        }}
      >
        <TextField variant="standard" />
        <IconButton type="submit">
          <AddCircleRoundedIcon color="success" />
        </IconButton>
      </form>
    </ListItem>
  );
};

QuestionAddItem.propTypes = {
  facilityId: PropTypes.string,
};

const addQuestion = async (facilityId, question) => {
  await addDoc(
    collection(db, ENV, VERSION, "facilities", facilityId, "surveyQuestion"),
    {
      question,
      createdAt: new Date(),
    }
  );
};

export default Qr;
