import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const languageState = atom({
    key: 'language',
    default: "en",
    effects_UNSTABLE: [persistAtom]
});

export default languageState;